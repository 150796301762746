<template>
    <div class="main">
        <div class="container text-dark py-70">
            <a-row type="flex" justify="space-between" align-item="center">
                <a-col :span="5">
                    <h2 class="text-dark timbeter_text">Timbeter</h2>
                </a-col>
                <a-col :span="14">
                    <img src="https://timbeter.com/wp-content/uploads/2021/04/TABLET-HAND.png" alt="Timbeter" class="industry-featured-image img-fluid">
                </a-col>
            </a-row>
            <a-row>
                <a-col>
                    <h3 class="has-text-align-center ">Timbeter is the easiest and quickest solution for measuring roundwood and managing all the data digitally.</h3>
                    <h4 class="has-text-align-center  text-dark">Timbeter determines the number of logs, volume and diameter of each log, whether in a pile, on a truck or in a container.</h4>
                    <p>Download the application for <a href="https://play.google.com/store/apps/details?id=ee.timberdiameter&amp;hl=en">Android</a> or <a href="https://apps.apple.com/us/app/timbeter/id1249312902?itsct=apps_box&amp;itscg=30200">iOS</a> smartphone or tablet, take a photo with Timbeter of the timber whether in a pile, on a truck or in a container. Timbeter determines the number of logs, volume and diameter of each log. Every pile has is geotagged that makes tracking the timber’s origins easier.</p>
                    <p>Measurements are stored in a cloud providing a real-time overview of your measurements. Timbeter’s storage enables you to analyze and share your measurements quickly. You can view inventories, active storage statuses and create instant reports easily.</p>
                    <h4 class="wp-block-heading"><strong>Features include:</strong></h4>
                    <p>– Pictures saved in the cloud</p>
                    <p>– Timbeter contains more than 10 formulas which are used by log measurers all over the world, for example, JAS, Scribner, among other</p>
                    <p>– Timbeter is available in 17 languages, including English, Spanish, Portuguese, German, Polish, Japanese, and many more</p>
                    <p>– Results easily shared via a link or e-mail (engraved picture)</p>
                    <p>– All the pictures can be re-counted</p>
                    <p>– To access the Storage module, go to <a href="https://www.timbeter.com/">timbeter.com</a>, log in with your email address and use all the benefits provided</p>
                    <p>– Timbeter can be integrated via an API with your other company tools including CRMs, bookkeeping, payroll or ERP, thus streamlining your sales, logistics planning and reporting</p>
                    <p><strong>Try it today for free when you register a new account!</strong></p>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<style scoped>

.main{
    width:100%;
    background-color: #e0e0e0;
}
.ant-col{
    padding-left: 15px;
    padding-right: 15px;
}
.container{
    width: 1140px;
    margin: auto;
}
.text-dark {
    color: #6d6e71 !important;
}
.timbeter_text{
    margin-top: 50%;
}
.pb-70, .py-70 {
    padding-bottom: 70px !important;
}
.pt-70, .py-70 {
    padding-top: 70px !important;
}
h2, .blog-single .title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 3.125rem;
    margin-bottom: 2.75rem;
}
.img-fluid, .wp-caption img {
    max-width: 100%;
    height: auto;
}
.has-text-align-center {
    text-align: center;
}

h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 2rem;
    color: #6d6e71;
}
h4, .h4 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
}
.wp-block-heading{
    text-align: left;
}
p {
    color: #6d6e71;
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 1.5;
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
}
</style>